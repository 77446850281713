import { Message } from 'element-ui'

var socket_suciz
var socket_suciz_lock32
var ref

function getSetSn () {
  var sn = 0
  return sn
}

export function Lock32_Function (rand, sn, callback) {
  var sn = getSetSn()
  var oJson = {
    'function': 'InitiateLock',
    'sn': sn
  }

  var sJson = JSON.stringify(oJson)

  var sStatus
  $.ajax({
    url: 'http://127.0.0.1:17681',
    data: 'json=' + sJson,
    dataType: 'jsonp',
    jsonp: 'callback',
    timeout: 3000,
    jsonpCallback: 'jsonpcallback',
    success: function (ojson) {
      console.info(ojson)
      if (ojson[0].ret == true) {
        var oJsonLock = {
          'function': 'Lock32_Function',
          'sn': sn,
          'rand': rand
        }
        var sJsonLock = JSON.stringify(oJsonLock)
        console.info(sJsonLock)
        $.ajax(
          {
            url: 'http://127.0.0.1:17681',
            data: 'json=' + sJsonLock,
            dataType: 'jsonp',
            jsonp: 'callback',
            jsonpCallback: 'jsonpcallback'
          }).done(function (ojson) {
          var sjson = JSON.stringify(ojson[0])
          sStatus += sjson
          socket_suciz_lock32 = ojson[0].lock
          console.info(socket_suciz_lock32)
          callback(socket_suciz_lock32)
        })
      }
    },
    error: function (xhr,status,error) {
      // Message.error('请安装安全狗插件.')
    },
  })
  var pac = 0
  pac += 9
}


