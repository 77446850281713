<template>
  <body id="poster">
    <div class="login-box">
      <div class="left">
        <img :src="require('@/assets/imgs/login2.png')" alt="" />
      </div>
      <div class="right">
        <h3 class="login_title">用户登录</h3>
        <el-form
          class="login-container"
          label-position="left"
          label-width="0px"
        >
          <el-form-item>
            <el-input
              type="text"
              v-model="loginForm.username"
              auto-complete="off"
              placeholder="请输入账号"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-input
              type="password"
              v-model="loginForm.password"
              auto-complete="off"
              placeholder="请输入密码"
              prefix-icon="el-icon-unlock"
            ></el-input>
          </el-form-item>
          <el-form-item style="background: none; border: 0">
            <div id="geetest"></div>
          </el-form-item>
          <el-form-item>
            <div class="other">
              <a href="https://ukey.oss-cn-beijing.aliyuncs.com/ukey.zip">
                点击下载加密狗及说明书
              </a>
              <el-button type="text" @click="reset">重置</el-button>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="width: 100%; background: #FC930C; border: none"
              :loading="loading"
              v-on:click="doLogin"
            >
              登录
            </el-button>
          </el-form-item>
          <!-- -->
        </el-form>
        <div class="memo">
          <div class="title">
            温馨提示
          </div>
          <div class="content">
            <p>一：提示账号或密码错误时。</p>
            <p>需要确认输入是否正确，是否开启了大写导致输入错误。</p>
            <p>二：提示加密狗错误时。</p>
            <p>1、Ctrl+F5刷新后重试登录。</p>
            <p>
              2、需要确认加密狗是否正常工作，Ctrl+Shift+Delete清除浏览器缓存后刷新重试登录。
            </p>
            <p>3、卸载加密狗驱动再重装后刷新重试登录</p>
          </div>
          <div class="contact">
            <el-popover placement="top" width="190" trigger="hover">
              <div class="popover-box">
                <img
                  style="width: 165px; height: auto"
                  src="@/assets/imgs/wx1.jpg"
                  alt=""
                />
                <div
                  style="
                color: red;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
              "
                >
                  15054576533
                </div>
              </div>
              <span slot="reference">
                <span class="el-icon-s-custom" style="margin-top:0">
                  联系客服
                </span>
              </span>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { login, loginUserInfo, startCaptcha } from '@/api/user.js'
import Message from 'element-ui/packages/message/src/main'
import '@/utils/gt'
import { Lock32_Function } from '../../../public/js/lyfweb'
export default {
  name: 'Login',
  data() {
    return {
      validResult: null,
      loading: false,
      loginForm: {
        //username: process.env.VUE_APP_USER_NAME,
        //password: process.env.VUE_APP_PASSWORD,
        username: '',
        password: '',
        dogCode: '',
        rand: 0
      },
      responseResult: []
    }
  },
  methods: {
    reset() {
      this.loginForm.username = ''
      this.loginForm.password = ''
    },
    //加密狗赋值
    sendVal(lock32) {
      this.loginForm.dogCode = lock32
    },
    async doLogin() {
      this.loading = true
      let that = this
      if (!this.validResult) {
        Message.warning('请进行验证！')
        that.loading = false
        return
      }
      window.localStorage.clear()
      let data = Object.assign(this.loginForm, this.validResult)
      await login(data)
        .then(res => {
          loginUserInfo()
            .then(resp => {
              let roles = []
              if (resp.userLevel === 0) {
                roles.push('SUPER')
              }

              window.localStorage.setItem('roles', roles)
              sessionStorage.setItem('userInfo', JSON.stringify(resp))
              that.$store.commit('user/SET_INFO')
              that.loading = false
            })
            .catch(err => {
              that.loading = false
            })
          this.$router.push('/')
        })
        .catch(err => {
          that.loading = false
        })
    },
    init() {
      this.validResult = null
      startCaptcha()
        .then(resp => {
          let obj = JSON.parse(resp)
          initGeetest(
            {
              gt: obj.gt,
              challenge: obj.challenge,
              new_captcha: obj.new_captcha,
              product: 'popup',
              offline: !obj.success,
              width: '100%'
            },
            captchaObj => {
              captchaObj.appendTo('#geetest')
              captchaObj
                .onReady(() => {
                  //your code
                  // console.log("onReady");
                })
                .onSuccess(() => {
                  this.validResult = captchaObj.getValidate()
                })
                .onError(() => {
                  // console.log("onError");
                  //your code
                })
            }
          )
        })
        .catch(err => {
          console.info(err)
          console.info('errr')
        })
    }
  },
  mounted() {
    this.loginForm.rand = Math.floor(
      (Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, 5)
    )
    Lock32_Function(this.loginForm.rand, 0, this.sendVal)
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#poster {
  background: url('../../assets/imgs/bg.jpg') no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  .login-box {
    align-self: center;
    display: flex;
    height: 590px;
    border-radius: 5px;
    overflow: hidden;
    > div {
      width: 448px;
      height: 100%;
    }
    .left {
      background: url('../../assets/imgs/login1.png') no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        left: 50%;
        bottom: 113px;
        transform: translateX(-50%);
      }
    }
    .right {
      background: white;
      overflow: hidden;
      h3 {
        margin: 0;
        padding: 0;
        background: #f8f9fb;
        font-size: 16px;
        font-weight: 400;
        color: #2f3351;
        text-align: center;
        line-height: 42px;
        font-weight: 600;
        border-bottom: 1px solid #f2f2fb;
      }
      .login-container {
        width: 292px;
        padding-top: 20px;
        margin: 0 auto;
      }
      ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #edeeef;
      }
      ::v-deep .el-form-item {
        margin-bottom: 15px;
      }
      .other {
        display: flex;
        justify-content: space-between;
        a {
          color: #1453b2;
        }
        button {
          color: #1453b2;
        }
      }
      .memo {
        .title {
          color: #b1bad6;
          font-size: 14px;
          padding-left: 23px;
          margin-bottom: 10px;
        }
        .content {
          background: #f9fbfd;
          border: 1px solid #f2f2fb;
          border-radius: 3px;
          padding: 10px;
          width: 400px;
          margin: 0 auto;
          p {
            padding: 0;
            margin: 0;
            font-size: 12px;
            font-weight: 300;
            color: #1a1a1b;
            line-height: 22px;
          }
        }
      }
      .contact {
        text-align: center;
        .el-icon-s-custom {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #6b6666;
          cursor: pointer;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
